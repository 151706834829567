import React from "react"
import { Typography, Container, Grid, Box, Link } from "@material-ui/core"
import { RoomOutlined } from "@material-ui/icons"

import LayoutComponent from "../../../../components/layout.component"

export default function Commutatus() {
  return (
    <LayoutComponent>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container spacing={5} direction="row" alignItems="center">
            <Grid item md={6}>
              <img
                src="https://uxofeverything.com/static/fd74a92dae8e6a73d4734d968afcea89/6b64fc795f0d7a02d5a2345750cfea49.jpg"
                alt="cover"
                width="100%"
              />
            </Grid>
            <Grid item md={6}>
              <Box pb={2}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <img
                      src="https://uxofeverything.com/strapi/uploads/c2_0b46581eaf.jpg"
                      alt="logo"
                      width="250px"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography gutterBottom variant="h5" component="h3">
                Commutatus
              </Typography>
              <Typography gutterBottom variant="body1" component="p">
                The company was established in the year 2014, and in just a few
                years, it has earned a place in{" "}
                <b>the top 10 UI UX design companies in Chennai</b>
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Average rate: $25-$49
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Employees: 10-49
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Website:{" "}
                <Link href="http://www.commutatus.com/" target="_blank">
                  http://www.commutatus.com/
                </Link>
              </Typography>
              <Box display="flex" flexDirection="row">
                <RoomOutlined fontSize="small" />
                <Typography variant="body2" component="p">
                  Address | Spencer Plaza, 715-A, Suite No.663, 7th Floor, Anna
                  Salai, Chennai, Tamil Nadu 600008
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container justify="center" direction="row">
            <Grid item>
              <Typography gutterBottom variant="h5" component="h2">
                About
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" direction="row">
            <Grid item md={10}>
              <Typography variant="body1" component="p">
                The company was established in the year 2014, and in just a few
                years, it has earned a place in{" "}
                <b>the top 10 UI UX design companies in Chennai</b> due to the
                team's ability to stay updated with the latest design trends and
                to combine latest technologies with innovative designs. The name
                of the company is derived from a Latin word, meaning change,
                which is in line with the team's ability to keep up with changes
                in the tech industry. Based in Chennai, the team has worked on
                several successful projects like Mine Mentor, Alley, Leanspoon,
                AIESEC, Tested, and Gehna. The Commutatus team has taken on
                varied projects over the past few years, which further goes on
                to show their strengths in developing different products for
                different industries.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}
